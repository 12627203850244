/* css variables */

*{
    --color1: #1D8717;
    --color2: #23A61C;
    --color3: #3d436b;
    --color4: #c7ccee;
    --gray2: #d4d4d4;
    --black1: #1e1e1e;
    --white: #ffffff;
    --gray1: #f7f7f7;
    --space-unit: .5rem;
    --medium-container-width: 80rem;
    --small-container-width: 55rem;
    --card-border-radius: .5rem;
}


.flex-1 {
  flex: 1;
}

.m-inline-end {
  margin-inline-end: 15px;
}

.srOnly {
  position:absolute;
  z-index: -5;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  }

body { 
  background-color: var(--white);
   font-family: 'Open Sans', sans-serif;
   overflow-x: hidden;
   
  }

 p {margin: var(--space-unit) 0;}


 /* upper navigation */

 .upperNav {
  min-height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray1);
  box-shadow: 0 1px 7px rgb(0 0 0 / 7%);
  padding-inline: calc(var(--space-unit) * 4);
  font-size: calc(var(--space-unit) * 2.5);
  z-index: 10;
}

@media (max-width: 499px) {
  .upperNav {
    padding-top: var(--space-unit);
  }
}

.upperNav h1 {
  color: var(--color1);
  font-size: calc(var(--space-unit) * 2.5);
}

@media (max-width: 499px) {
  .upperNav h1  {
    padding-inline: 0;
    font-size: calc(var(--space-unit) * 1.6);
  }
}

.upperNav p {
  display: inline-block;
  color: var(--color1);

}

.upperNav a {
  color: var(--color7);
  text-decoration: none;
  transition: color .3s ease;
}

@media (max-width: 499px) {
  .upperNav a  {
    font-size: calc(var(--space-unit) * 1.6);
  }
}


.upperNav nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-inline: calc(var(--space-unit) * 4);
  box-sizing: border-box;
 flex:1;
}

@media (max-width: 499px) {
  .upperNav nav {
    padding-inline: 0;

  }
}

.upperNav nav a:hover {
  color: var(--color3);
}

.upperNav .logo {
  flex: 1;
  box-sizing: border-box;
  padding-inline: calc(var(--space-unit) * 4);
}

@media (max-width: 499px) {
  .upperNav .logo  {
    padding-inline: 0;
  }

  .upperNav .logo svg {
    width: 60px;
  }
}


/* main */
main{
  min-height: 90vh;
  max-width: 100vw;
}

/* home */

.home-hero {
  padding-block: calc(var(--space-unit) * 10);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(../public/pics/line.svg);
  background-size: 900px;
  background-position: top 20px center;
  background-repeat: no-repeat;
/* background-attachment: fixed; */
  box-sizing: border-box;

}


@media (max-width: 499px) {
  .home-hero {
    background-image: none;
    padding: calc(var(--space-unit) * 3);
  }
}

.home-hero .home-hero__container {
  max-width: var(--medium-container-width);
}

.home-hero .home-hero__container h2 {
  text-align: center;
  font-size: calc(var(--space-unit) * 4);
  line-height: calc(var(--space-unit) * 5);
}

@media (max-width: 499px) {
  .home-hero .home-hero__container h2 {
    font-size: calc(var(--space-unit) * 3);
  }
}

.home-hero .home-hero__container p {
  text-align: center;
  color: var(--color3);
  font-weight: 600;
  line-height: calc(var(--space-unit) * 4);
}

.categories__nav {
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: var(--color3);
}

@media (max-width: 499px) {
  .categories__nav {
  margin-top: 0;
  }
}

.categories__nav a{
padding-inline:  calc(var(--space-unit) * 3);
padding-block: var(--space-unit);
text-decoration: none;
color: var(--gray1);
font-weight: 600;
transition: all.3s ease-in-out;
}

.categories__nav a:hover {
  color: var(--white);
  background-color: var(--color1);
}

/* footer */
footer {
  min-height: 5vh;
  background-color: var(--gray1);
}

/* categories */

.categories__unit {
  display: flex;
  flex-direction: column;
  align-items: center;
 }

.categories__unit:nth-child(odd){
  background-color: var(--gray1);
}

.categories__container{
  max-width: var(--medium-container-width);
    width: 100%;
    padding-block: calc(var(--space-unit) * 3) ;
}

.categories__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: calc(var(--space-unit) * 2);
}

.categories__title {
  text-align: center;
  font-size: calc(var(--space-unit) * 3);
}  

.categories__description {
    max-width: 950px;
    border: 1px solid #26cf40;
    padding: calc(var(--space-unit) * 3);
    line-height: calc(var(--space-unit) * 4);
    text-align: center;
    margin-bottom: calc(var(--space-unit) * 3);
    border-radius: calc(var(--space-unit) * 1);

}

/* cards */
.card {
  border-radius: var(--card-border-radius);
  background-color: var(--gray1);
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.0);
  transition: all .5s ease-in-out;
  flex: 1;
  text-decoration: none;
}
.card:hover {
  box-shadow: 0 1px 18px 3px rgba(0, 0, 0, 0.137);
}

@media (max-width: 499px) {
  .card {
    box-shadow: none;
  }

  .card:hover {
    box-shadow: none;
  }
}

.card .storiesGallery__info {
  width: 100%;
  flex: 1;
  margin-top: var(--space-unit);
  
}

.card  h3 {
  color: var(--color3);
  padding-inline-start: var(--space-unit);
  margin-block: var(--space-unit);
  display: inline-block;
}

.card  h4{
  margin-block: var(--space-unit);
  padding-inline: var(--space-unit);
  display: inline-block;
  color: var(--color3);
}

.card p {
display: inline-block;
color: var(--black1);
padding-inline: var(--space-unit);
margin-top: 0;
}

.card .editor {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray2);
  padding: var(--space-unit);
  border-radius: var(--space-unit);
  position: absolute;
  z-index: 5;
  right: 20px;
  top: 20px
}

.card .editor>* {
  margin-inline: 4px ;
}



/* editor menu */
.editor__deleteBTN {
  height: 40px;
  width: 40px;
  background-image: url("../public/pics/icons8-trash.svg");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--gray1);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all .3s ease;
}


.editor__editLink{
  display: inline-block;
  height: 40px;
  width: 40px;
  background-image: url("../public/pics/icons8-edit.svg");
  background-color: var(--gray1);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all .3s ease;
}

.card .img-container {
  border-radius: var(--card-border-radius);
  overflow: hidden;
  height: 300px;
  width: 100%;
}

.card .img-container img {
    object-fit: cover;
}

/* card1 - horizontal */
.storiesGallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
 
}

@media (max-width: 499px) {
  .storiesGallery {
    flex-direction: column;
  }
}

.card-horizontal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-width: 33.333%;
  min-width: 350px;
  padding: calc(var(--space-unit) * 2);
  margin: calc(var(--space-unit) * 2);
  position: relative;
}

@media (max-width: 499px) {
  .card-horizontal {
    margin: 0;
    min-width: auto;
  }
}

@media (max-width: 499px) {
  .card-horizontal {
    max-width: 100%;
  }
}

@media (min-width: 500px) and (max-width: 1000px) {
  .card-horizontal {
    max-width: 50%;
  }
}

.card-horizontal img {
  max-width: 400px;
  min-width: 100%;
}

.card-horizontal ul {
  list-style: none;
  padding: 0;
  margin: var(--space-unit) 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

.card-horizontal ul li {
padding: 3px var(--space-unit);
margin: 3px;
color: #000;
border: 4px solid var(--white);
border-radius: var(--space-unit);
font-size: 14px;
font-weight: 600;
}

.ads {background-color: #d5f0de;}
.education {background-color: #fdfbea;}
.physical {background-color: #d5f6ff;}

/* story-view */
.story-view__hero {
  min-height: 500px;
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 499px) {
  .story-view__hero{
    min-height: 200px;
  }
}

.story-view__hero-content {
  position: absolute;
  bottom: -69px;
  z-index: 5;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}

/*story contact info */
.story-view__contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 var(--space-unit) 0;
  margin: var(--space-unit) 0 0 0;
  border-bottom: 3px solid var(--color2);
}
.story-view__contact-info li {
  margin-inline: var(--space-unit);
  cursor: pointer;
}

.story-view__title {
  text-align: center;
  flex: 1;
  margin: 0;
  color: var(--black1);
  font-size: calc(var(--space-unit) * 7);
  background-color: var(--gray1);
  padding: calc(var(--space-unit) * 2) calc(var(--space-unit) * 6);
  border-top-right-radius: calc(var(--space-unit) * 2);
  border-top-left-radius: calc(var(--space-unit) * 2);
}

@media (max-width: 499px) {
  .story-view__title{
    font-size: calc(var(--space-unit) * 3);
    padding: calc(var(--space-unit) * 2) calc(var(--space-unit) * 3);
  }
}

.story-view__description {
  flex: 1;
  margin: 0;
  text-align: center;
 color: var(--white);
font-size: calc(var(--space-unit) * 3);
font-weight: 600;
background-color: var(--color2);
padding: calc(var(--space-unit) * 2) calc(var(--space-unit) * 6);
}

@media (max-width: 499px) {
  .story-view__description{
    font-size: calc(var(--space-unit) * 2);
    padding: calc(var(--space-unit) * 2) calc(var(--space-unit) * 3);
  }
}

.story-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 499px) {
  .story-view{
 align-items: normal;
  }
}

.pre-interview-description {
  padding: calc(var(--space-unit) * 3);
  background-color: var(--gray1);
}

.story-view__text {
  font-size: calc(var(--space-unit) * 2.2);
   max-width: var(--small-container-width);
   padding-top: calc(var(--space-unit) * 15);
   padding-inline: calc(var(--space-unit) * 3);
}

.story-view__text img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.story-view__text p {
  margin-block: calc(var(--space-unit) * 1.3);
}

.story-view__text h3 {
margin-block: calc(var(--space-unit) * 3) calc(var(--space-unit) * 2);
}

@media (max-width: 499px) {
  .story-view__text{
    padding-top: calc(var(--space-unit) * 10);
  }
}

.story-view__text h4 {
  color: white;
  font-size: calc(var(--space-unit) * 2);
  display:block;
  background-color: var(--color1);
  margin: -16px 0 0 0;
  padding: var(--space-unit) calc(var(--space-unit) * 2);
  font-weight: 400;
  }

.story-view__text .img-container {
  margin-block-start: calc(var(--space-unit) * 3);
}

.story-view__text .img-caption {
  background-color: var(--black1);
  color: var(--white);
  padding: var(--space-unit);
  margin-top: -7px;
}

.story-view__text h2 {
color: var(--color3);
font-weight: 700;
text-align: center;
font-size: calc(var(--space-unit) * 4);
}

.story-view__text h3 {
  font-weight: 600;
}

.story-view__contact-info a{
  display: block;
 height: 50px;
 width: 50px;
 display: flex;
 align-items: center;
 justify-content: center;
}

@media (max-width: 499px) {
  .story-view__contact-info a{
    height: 30px;
    width: 30px;
  }
}

.story-view__contact-info svg  {
 height: 30px;
 width: 30px;
 
}

/* info menu */
.info__web, .info__WhatsApp, .info__facebook, .info__linkedin, .info__instagram, .info__X, .info__whatsupp   {
  fill : var(--color3);
  transition: fill .3s ease;
}



.info__web:hover, .info__WhatsApp:hover, .info__facebook:hover, .info__linkedin:hover, .info__instagram:hover, .info__X:hover 
{
  fill : var(--color2);
}

.info__email, .info__phone, .info__whatsupp  {
  stroke : var(--color3);
  transition: stroke .3s ease;
}

.info__email:hover, .info__phone:hover, .info__whatsupp:hover {
  stroke : var(--color2);
}

/* contact-card  */
.story-view__contact-container {
  background-color: var(--gray1);
  width: 100%;
  display:  flex;
  justify-content: center;
  margin-top: calc(var(--space-unit) * 6);
}
.story-view__contact-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: calc(var(--space-unit) * 6);
  padding: calc(var(--space-unit) * 4);
  width: var(--medium-container-width);
  overflow: hidden;
  max-height: 600px;
  box-sizing: border-box;
  line-height: calc(var(--space-unit) * 4);
  background-color: var(--white);
}

.story-view__contact-card ul {
  display: block;
}

@media (max-width: 749px) {
  .story-view__contact-card {
    flex-direction: column;
    width: 90%;
    max-height: fit-content;
    padding: calc(var(--space-unit) * 2);
  }
}

@media (min-width: 1300px) and (max-width: 1650px) {
  .story-view__contact-card {
    width: 90%;
    max-height: fit-content;
   
  }
}

@media (min-width: 750px) and (max-width: 1299px) {
  .story-view__contact-card {
    width: 90%;
    max-height: fit-content;
    flex-direction: column;
  }
}


.story-view__contact-card__imgContainer {
  min-height: 100%;
  box-sizing: border-box;
  flex: 1;
  overflow: hidden;
}

.story-view__contact-card img {
  object-fit: cover;
  width: 400px;
  height: 400px;
  border-radius: 50%;
}

.story-view__contact-card strong {
 color: var(--color1);
}

@media (max-width: 499px) {
  .story-view__contact-card__imgContainer {
    flex: auto;
    max-height: 390px;
    min-height: auto;
  }
  .story-view__contact-card img {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: 500px) and (max-width: 1300px) {
  .story-view__contact-card__imgContainer {
    min-height: auto;
    flex: auto;
  }
  .story-view__contact-card img {
    width: 300px;
    height: 300px;
  }
}

.story-view__contact-card__textInfo {
  flex: 1.5;
  box-sizing: border-box;
}


@media (max-width: 499px) {
  .story-view__contact-card__textInfo {
    flex: auto;
    margin-top: calc(var(--space-unit) * 4);
  }
  .story-view__contact-card__text {
    padding: var(--space-unit) calc(var(--space-unit) * 1);
  }
}

@media (min-width: 500px) and (max-width: 1000px) {
  .story-view__contact-card__textInfo{
    margin-top: calc(var(--space-unit) * 4);
    padding-inline: calc(var(--space-unit) * 2);
  }}


.story-view__contact-card__textInfo h3 {
  font-weight: 200;
  margin: 0 0 2rem 0;
  color: var(--color3);
  font-size: calc(var(--space-unit) * 5);
}

@media (max-width: 499px) {
  .story-view__contact-card__textInfo h3 {
    font-size: calc(var(--space-unit) * 4);
    text-align: center;
  }
}

@media (min-width: 500px) and (max-width: 1300px) {
  .story-view__contact-card__textInfo h3{
    text-align: center; 
  }}


.story-view__contact-card__textInfo ul {
  padding-inline-start: 24px;
}


.story-view__contact-card__textInfo p {
  font-size: calc(var(--space-unit) * 2.5);
}

.story-view__contact-card__textInfo li {
  margin-bottom: 0;
}

.story-view__contact-card__textInfo ul a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  font-size: calc(var(--space-unit) * 2.5);
  color: var(--black1);
  transition: all .3s ease;
  padding: calc(var(--space-unit) * 2);
  font-size: calc(var(--space-unit) * 2);

}

.story-view__contact-card__textInfo ul a:hover {
  color: var(--color1);
}

.story-view__contact-card__textInfo ul a svg {
  height: 30px;
  width: 30px;
  margin-bottom: 4px;

}

.story-view__contact-card__textInfo .info__web, .story-view__contact-card__textInfo .info__WhatsApp,.story-view__contact-card__textInfo .info__facebook, .story-view__contact-card__textInfo .info__linkedin, .story-view__contact-card__textInfo .info__instagram, .info__X   {
  fill : var(--black1);
  transition: all .3s ease;
}

.story-view__contact-card__textInfo .info__web:hover, .story-view__contact-card__textInfo .info__WhatsApp:hover,.story-view__contact-card__textInfo .info__facebook:hover, .story-view__contact-card__textInfo .info__linkedin:hover, .story-view__contact-card__textInfo .info__instagram:hover, .story-view__contact-card__textInfo .info__X:hover   {
  fill : var(--color1);
  transition: fill .3s ease;
}

.story-view__contact-card__textInfo .info__email, .story-view__contact-card__textInfo .info__phone  {
  stroke : var(--black1);
  transition: all .3s ease;
  direction: ltr;
}

.story-view__contact-card__textInfo .info__email:hover, .story-view__contact-card__textInfo .info__phone:hover  {
  stroke : var(--color1);
}


/* footer */

.footer {
  display: flex;
  align-items: center;
  padding: calc(var(--space-unit) * 3);
  background-color: var(--color2);
}
.footer >div {
margin-inline-end: calc(var(--space-unit) * 2);
}
.companies {
    background-color: var(--back-color);
    min-height: 100vh;
    padding: calc(var(--space-unit) * 10);
}


.companies h1 {
    color: var(--color1);
    font-size: calc(var(--space-unit) * 5);
    font-size: 'Passion One', cursive;
    font-weight: 700;
    text-align: center;
    margin-bottom: calc(var(--space-unit) * 8);
}

.companies__title {
    display: block;
    color: var(--color4);
    font-size: calc(var(--space-unit) * 4);
    font-size: 'Passion One', cursive;
    font-weight: 700;
    margin-bottom: calc(var(--space-unit) * 4);
}

.companies__info {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    max-width: 700px;
    font-size: calc(var(--space-unit) * 2.5);
    line-height: calc(var(--space-unit) * 4);
}
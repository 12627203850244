legend {
  width: 100%;
  margin-bottom: var(--space-unit);
  text-align: center;
  font-weight: bold;
}

 input, textarea { width: 100%;  box-sizing: border-box;}
  

.createStory {
    max-width: 900px;
    padding: 15px;
    margin: 0 auto;
}

.createStory form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}


.createStory__group {
    display: flex;
    justify-content: space-between;
}

.createStory__unit label {
  font-weight: 600;
  color: var(--color3);
}

.createStory__unit.m-inline-end {
margin-right: 1.2rem;
}

.createStory form input {
    margin-block: 0.2rem 1rem;
    height: 50px;
    padding-inline-start: 1rem;
    font-size: 1.2rem;
    border: 1px solid var(--gray2);
}

.comboBox {
  margin-bottom: 15px;
}


textarea {
    min-width: 100%;
    font-size: 1.2rem;
    margin-block: 1rem;
}
.padding-inline-start input {
  padding: 0;
}

.createStory__group__image.createStory__group__image input{
    border: 1px solid var(--color3);
    padding: 0;
  
}

input::file-selector-button {
    background:  var(--color1);
    height: 50px;
    border: none;
    color: #fff;
    padding-inline: var(--space-unit);
  }

  .createStory__uploadFile {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: var(--color3);
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-size: calc(var(--space-unit) * 2);
    width: calc(var(--space-unit) * 20);
    margin-block: 0.2rem 1rem;
  }

  .createStory__group__image  .createStory__unit {
    display: flex;
    align-items: center;
  }


  .createStory__submit {
    height: 60px;
    color: #fff;
    background-color: var(--color3);
    border: none;
    cursor: pointer;
    font-size: calc(var(--space-unit) * 2);
    width: 100%;
  }

  .quill {
    margin-block: 1rem;
  }

  .quill .ql-editor {
    height: 600px;
  }

